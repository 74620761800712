var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('span',{class:{
    'font-weight-bold': _vm.bold,
  }},[(_vm.dyestuffs.length)?_c('span',[_vm._v(" 1剤: "),_vm._l((_vm.dyestuffs),function(usedDyestuff,idx){return _c('span',{key:idx,style:({
        'background-color': _vm.getDyestuffBGColor(usedDyestuff.brand),
        color: _vm.getDyestuffFontColor(usedDyestuff.brand),
      })},[_vm._v(" "+_vm._s(usedDyestuff.name)+"("+_vm._s(_vm.getAmount(usedDyestuff.amount))+"g) ")])})],2):_vm._e(),_c('br'),(_vm.solutions.length)?_c('span',[_vm._v(" 2剤 "+_vm._s(_vm.solutions .map( function (ref) {
        var percentage = ref.percentage;
        var amount = ref.amount;

        return (percentage + "%(" + (_vm.getAmount(amount)) + "g)");
} ) .join(', '))+" ")]):_vm._e()])}
var staticRenderFns = []

export { render, staticRenderFns }