var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-data-table',{attrs:{"items":_vm.items,"headers":_vm.headers,"loading":_vm.loading,"items-per-page":_vm.items.length,"hide-default-footer":"","dense":"","fixed-header":"","height":_vm.height,"item-class":_vm.rowColor},scopedSlots:_vm._u([{key:"item.date",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(_vm._f("date")(item.date,"'YY MM/DD"))+" ")]}},{key:"item.treatmentText",fn:function(ref){
var item = ref.item;
return [_vm._v(" "+_vm._s(item.treatmentName || (item.treatmentText ? item.treatmentText.replace('トリートメント', '') : 'なし'))+" ")]}},{key:"item.dyestuffRoot",fn:function(ref){
var item = ref.item;
return [_c('UsedDyestuffs',{attrs:{"dyestuffs":item.usedDyestuffRoots,"solutions":item.usedSolutionRoots,"dyestuff-amount":item.dyestuffAmountRoot}})]}},{key:"item.dyestuffEnd",fn:function(ref){
var item = ref.item;
return [_c('UsedDyestuffs',{attrs:{"dyestuffs":item.usedDyestuffEnds,"solutions":item.usedSolutionEnds,"dyestuff-amount":item.dyestuffAmountEnd}})]}},{key:"item.note",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"left":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"text-no-wrap text-truncate",staticStyle:{"max-width":"300px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[_c('div',[_vm._l((String(value).split('\n')),function(s,idx){return [(idx !== 0)?_c('br',{key:("br-" + idx)}):_vm._e(),_c('span',{key:idx},[_vm._v(" "+_vm._s(s)+" ")])]})],2)])]}},{key:"item.rating.freeComment",fn:function(ref){
var value = ref.value;
return [_c('v-tooltip',{attrs:{"left":"","max-width":"600px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var attrs = ref.attrs;
var on = ref.on;
return [_c('div',_vm._g(_vm._b({staticClass:"text-no-wrap text-truncate",staticStyle:{"max-width":"200px"}},'div',attrs,false),on),[_vm._v(" "+_vm._s(value)+" ")])]}}],null,true)},[_c('div',[_vm._l((String(value).split('\n')),function(s,idx){return [(idx !== 0)?_c('br',{key:("br-" + idx)}):_vm._e(),_c('span',{key:idx},[_vm._v(" "+_vm._s(s)+" ")])]})],2)])]}},{key:"body.append",fn:function(){return [_c('tr',[_c('td',{attrs:{"colspan":_vm.headers.length}},[_c('infinite-loading',{attrs:{"identifier":_vm.customer},on:{"infinite":_vm.fetchData}})],1)])]},proxy:true}])})}
var staticRenderFns = []

export { render, staticRenderFns }